import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Seo from "components/Seo"

import '../../static/styles/pricing.css'

import testimonialHans from '../../static/assets/testimonial-hans.svg'
import { StaticImage } from 'gatsby-plugin-image'
import HomepageVideo from './HomepageVideo'
import FooterBanner from '../components/FooterBanner'

export default () => {
  const planName = {
    // free: 'Free',
    basic: 'Basic',
    pro: 'Pro',
    expert: 'Expert',
  }
  const activePlanKeys = ['basic', 'pro', 'expert'] // change to ['starter', 'pro'] to hide Free plan
  const featureData = [
    {
      feature: {
        title: 'Email sends',
      },
      plans: {
        basic: 'Up to 30,000 emails/month',
        pro: 'Up to 100,000 emails/month',
        expert: 'Up to 300,000 emails/month',
      },
    },
    {
      feature: {
        title: 'Email addresses',
        description: 'Accounts for sending',
      },
      plans: {
        basic: 'Up to 5',
        pro: 'Up to 20',
        expert: 'Up to 50',
      },
    },
    {
      feature: {
        title: 'LinkedIn accounts',
        description: 'Accounts for connecting/messaging',
      },
      plans: {
        basic: '1',
        pro: 'Up to 5',
        expert: 'Up to 15',
      },
    },
    {
      feature: {
        title: 'Prospect storage',
        description: 'With activity records & notes',
      },
      plans: {
        basic: 'Up to 10,000',
        pro: 'Up to 50,000',
        expert: 'Up to 100,000',
      },
    },
    {
      feature: {
        title: 'Team Members',
        description: 'With unique login',
      },
      plans: {
        basic: 'Unlimited',
        pro: 'Unlimited',
        expert: 'Unlimited',
      },
    },
    {
      feature: {
        title: 'Searchable audit logs',
        description: 'All activities tracked',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Expert support',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Prospects bulk import',
        description: 'Via CSV + Google drive',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'No duplicate prospects',
        description: 'Upload new prospects worry-free',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Unsubscribe management',
        description: 'In-app suppression list'
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'DoNotContact by domain',
        description: 'Suppress sending to entire orgs',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Prospect tags',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Custom attributes',
        description: 'Send highly personalized messages',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Auto import spreadsheets',
        description: 'Changes detected automatically',
      },
      plans: {
        basic: '1 Sheet',
        pro: 'Up to 10 sheets',
        expert: 'Up to 30 sheets',
      },
    },
    {
      feature: {
        title: 'Gmail-approved sending API',
        description: 'For superior deliverability',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Free AutoWarmer integration',
        description: 'Safe, seamless, and secure',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Bounce detection',
        description: 'Stops campaign immediately',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Email deliverability score',
        description: 'Based on last 10 days of sending',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Blacklist monitoring',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Email throttling',
        description: 'Custom daily send limits',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Deliverability report',
        description: 'Weekly in your inbox',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Inbox rotation',
        description: 'Assign multiple senders to a campaign',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Deliverability AI',
        description: 'Automatic replacement of bad senders',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Built-in error detection',
        description: 'Send messages without blunders',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Live campaigns',
        description: 'Reach out via multiple channels to increase response',
      },
      plans: {
        basic: 'Up to 10',
        pro: 'Up to 20',
        expert: 'Up to 50',
      },
    },
    {
      feature: {
        title: 'Email campaign steps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'SMS campaign steps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Call campaign steps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Task campaign steps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'A/Z testing',
        description: 'Variations for testing and deliverability',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Computed attributes',
        description: 'Mention colleagues, days in advance',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Sending times',
        description: 'Set days/times emails are sent',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Scheduling automation',
        description: 'Set days/times prospects enter a campaign',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Plain text sending',
        description: 'Optional deliverability boost',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Liquid syntax',
        description: 'Logic for variable text',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Auto stop on reply',
        description: 'Stop campaign when reply detected',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Stop colleagues on reply',
        description: 'Cancel campaign for colleagues',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Out of office detection',
        description: 'Auto pause campaign for 2 weeks',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Conditional sending',
        description: 'Steps based on engagement',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Sub-campaigns',
        description: 'Auto follow-up based on response',
      },
      plans: { basic: false, pro: false, expert: true },
    },
    {
      feature: {
        title: 'Campaign-level stats',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Live notifications',
        description: 'In-app for open & click',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Account dashboard',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Won/lost attribution',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Unsubscribe insights',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Advanced analytics',
        description: 'Tracked by send time',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Centralized reply handling',
        description: 'Supports multiple email providers',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Advanced reply detection',
        description: 'Detects any email from a prospect',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Snooze & archive replies',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Assign replies',
        description: 'Handle replies as a team',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Advanced filters',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Reply from any inbox',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Active/pending status',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Add notes to thread',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Visible wait time',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Labels',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'AI sentiment detection',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Auto unsubscribe',
        description: 'Detect and handle unsubscribe requests',
      },
      plans: { basic: false, pro: false, expert: true },
    },
    {
      feature: {
        title: 'Zapier',
        description: 'Integrate with 3,000+ apps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Email verification',
        description: 'Clean your prospect data in-app',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Google Drive',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Slack integration',
        description: 'Send messages to your channel',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Twilio integration',
        description: 'For SMS campaign steps',
      },
      plans: { basic: true, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Native CRM',
        description: '2-way sync with HubSpot & Pipedrive',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'Webhooks',
      },
      plans: { basic: false, pro: true, expert: true },
    },
    {
      feature: {
        title: 'API',
        description: 'Have your own integration',
      },
      plans: { basic: false, pro: false, expert: true },
    },
  ]

  const featureDataForMobile = featureData.reduce(
    (accumulator, rowData, _index, _array) => {
      const featureData = rowData.feature
      activePlanKeys.forEach((key) => {
        const planData = rowData.plans[key]
        let value
        if (typeof planData === 'boolean') {
          value = planData ? featureData : null
        } else {
          value = { title: planData }
        }
        const i = accumulator.findIndex((obj) => obj.plan === key)
        if (i > -1) {
          accumulator[i].features.push(value)
        }
      })
      return accumulator
    },
    activePlanKeys.map((key) => ({ plan: key, features: [] }))
  )

  const [yearlyBilling, setYearlyBilling] = useState()

  return (
    <>
    <Seo
      title={`Pricing | QuickMail`}
      description="QuickMail pricing"
      url="/pricing"
    >
      <script src="https://fast.wistia.com/embed/medias/qnk7mjpf0i.jsonp" async></script>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
    </Seo>
    <div
      style={{
        overflow: 'hidden',
      }}
      className="display-page"
      id="pricing"
    >
      <HomepageVideo />
      <div className="diagonal-group">
        <div className="container padded">
          <section className="row" id="hero">
            <div className="col-12 text-center">
              <h1>Start Sending Cold Email<br />That Gets Delivered Today</h1>
              <h4>Pay month-to-month and cancel whenever.<br />Try risk free for 14 days.</h4>
              {/* <button
                className={
                  'btn mb-5 mr-2 ' + (yearlyBilling ? 'gray-btn' : 'purple-btn')
                }
                onClick={() => setYearlyBilling(false)}
              >
                Monthly
              </button>
              <button
                className={
                  'btn mb-5 ' + (yearlyBilling ? 'purple-btn' : 'gray-btn')
                }
                onClick={() => setYearlyBilling(true)}
              >
                Yearly
              </button> */}
            </div>
          </section>
        </div>
      </div>
      <div className="diagonal-group white-diagonal-before blue-diagonal-after dark-blue-bg">
        <div
          className="container padded"
          style={{
            paddingBottom: '4rem',
          }}
        >
          <section className="row" id="tiers">
            {/* <div className="col-12 col-sm-10 col-md-4 col-lg-4 mx-auto d-flex">
              <div className="plan-card">
                <div>
                  <h4 className="plan-name">Free Plan</h4>
                  <small className="plan-description minheight">Free Plan</small>
                  <small className="plan-description minheight">Run cold email<br />for a single business</small>
                  <p className='minheight2'>
                    The only cold outreach platform<br/>with a
                    <b> true </b> free plan.
                  </p>
                  <div className="price">
                    $0 <small>/mo</small>
                  </div>
                  <ul>                  
                    <li>3,000 Emails Monthly</li>
                    <li>1,000 Active Prospects</li>
                    <li>5 Email Addresses</li>
                    <li>Unlimited Team Members</li>
                    <li>Free AutoWarmer with <a href="https://www.mailflow.io/" target="_blank">MailFlow</a></li>
                    <li>Reply Handling with AI</li>
                    <li>Expert Support</li>
                  </ul>
                </div>
                <div>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn"
                  >
                    Get Started
                  </a>
                  <small className="price-clarification">FREE PLAN</small>
                </div>
              </div>
            </div> */}

            <div className="col-12 col-sm-10 col-md-4 col-lg-4 mx-auto d-flex">
              <div className="plan-card">
                <div>
                  <small className="plan-description minheight">Basic Plan</small>
                  {/* <h4 className="plan-name">Basic Plan</h4> */}
                  <p className='minheight2'>
                    Engage and close prospects on autopilot.
                  </p>
                  <div className="price">
                    $49 <small>/mo</small>
                  </div>
                  <div className="description">
                    {/* Only available on individual accounts. */}
                  </div>
                  <ul>
                    <li>30,000 Emails Monthly</li>
                    <li>10,000 Active Prospects</li>
                    <li>5 Email Addresses</li>
                    <li>1 LinkedIn Account</li>
                    <li>Unlimited Team Members</li>
                    <li>Free AutoWarmer with <a href="https://www.mailflow.io/" target="_blank">MailFlow</a></li>
                    <li>Reply Handling</li>
                    <li>Zapier</li>
                    <li>Blacklists Monitoring</li>
                    <li>Expert Support</li>
                  </ul>
                </div>
                <div>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn"
                  >
                    Get Started
                  </a>
                  <small className="price-clarification">14-DAY FREE TRIAL</small>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-4 col-lg-4 mx-auto d-flex">
              <div className="plan-card">
                <div>
                  <small className="plan-description minheight">Pro Plan</small>
                  {/* <h4 className="plan-name">Pro Plan</h4> */}
                  <p className='minheight2'>
                    Powerful Stats & native integration with CRM.
                  </p>
                  <div className="price">
                    $89 <small>/mo</small>
                  </div>
                  <div className="description">
                    {/* Only available on individual accounts. */}
                  </div>
                  <ul>
                    <li>100,000 Emails Monthly</li>
                    <li>50,000 Active Prospects</li>
                    <li>20 Email Addresses</li>
                    <li>5 LinkedIn Accounts</li>
                    <li>Unlimited Team Members</li>
                    <li>Free AutoWarmer with <a href="https://www.mailflow.io/" target="_blank">MailFlow</a></li>
                    <li>Reply Handling</li>
                    <li>Zapier</li>
                    <li>Blacklists Monitoring</li>
                    <li>Expert Support</li>
                    <li>HubSpot & Pipedrive Native Integration</li>
                    <li>Deliverability AI</li>
                    <li>Advanced Analytics</li>
                  </ul>
                </div>
                <div>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn"
                  >
                    Get Started
                  </a>
                  <small className="price-clarification">14-DAY FREE TRIAL</small>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-4 col-lg-4 mx-auto d-flex">
              <div className="plan-card">
                <div>
                  <small className="plan-description minheight">Expert Plan</small>
                  <p className='minheight2'>
                    Advanced Analytics, AI & Sub-Campaigns
                  </p>
                  <div className="price">
                    $129 <small>/mo</small>
                  </div>
                  <div className="description">
                    {/* Only available on individual accounts. */}
                  </div>
                  <ul>
                    <li>300,000 Emails Monthly</li>
                    <li>100,000 Active Prospects</li>
                    <li>50 Email Addresses</li>
                    <li>15 LinkedIn Accounts</li>
                    <li>Unlimited Team Members</li>
                    <li>Free AutoWarmer with <a href="https://www.mailflow.io/" target="_blank">MailFlow</a></li>
                    <li>Reply Handling</li>
                    <li>Zapier</li>
                    <li>Blacklists Monitoring</li>
                    <li>Expert Support</li>
                    <li>HubSpot & Pipedrive Native Integration</li>
                    <li>Deliverability AI</li>
                    <li>Advanced Analytics</li>
                    <li>AI & Sub-Campaigns</li>
                    <li>API</li>
                  </ul>
                </div>
                <div>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn"
                  >
                    Get Started
                  </a>
                  <small className="price-clarification">14-DAY FREE TRIAL</small>
                </div>
              </div>
            </div>
          </section>

            {/* <div className="col-12 col-sm-10 col-md-4 col-lg-4 mx-auto d-flex">
              <div className="plan-card">
                <div>
                  <h4 className="plan-name">Agency Plan</h4>
                  <small className="plan-description minheight">Multiple clients<br/>under a single dashboard</small>
                  <p className='minheight2'>The most robust & customizable platform with full visibility across all your campaigns.</p>
                  <div className="price">
                    {yearlyBilling ? '$49' : '$100'} <small>/mo /account</small>
                  </div>
                  <p>
                     Get everything you need to deliver results on automation for all your clients. 
                  </p>
                  <div className="description">
                     Run your operation on a stable platform that can scale. 
                  </div>
                  <ul>
                    <li>100,000  Emails per Month 
                        <span className="description-price"><small>/ account</small></span>
                    </li>
                    <li>30,000 Prospects Storage
                    <span className="description-price"><small>/ account</small></span>
                    </li>
                    <li>40 Email Addresses
                    <span className="description-price"><small>/ account</small></span>

                    </li>
                    <li>Unlimited Team Members</li>
                    <li>Reply handling</li>
                    <li>Dedicated Support</li>
                    <li>Global Dashboard</li>
                    <li>Constant Blacklist Monitoring</li>
                  </ul>
                </div>
                <div>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn"
                  >
                    Get Started
                  </a>
                  <small className="price-clarification">14-Day Free Trial</small>
                </div>
              </div>
            </div> */}
            
          <section className="row">

            <div className="cta col-12 col-xl-12 offset-xl-1">
              <h2>Are You An Agency?</h2>
            </div>
            <div className="col-12 col-md-12 col-xl-12 offset-xl-1">
              <p className="text-white">
                Learn How Avadel <a title='Case Study' href="/testimonials/email-platform-for-agency" target="_blank">Added $347,000+ in MRR with QuickMail.</a>
              </p>
            </div>
            <div className="cta-details col-12 col-md-6 col-xl-5 offset-xl-1">
              <p>
                Have all your clients under one roof.
                Shift between client profiles with just one click.
                Give each client unique integrations, prospects, exclude list, and more.
              </p>
              </div>
            <div className="cta-details col-12 col-md-5 col-xl-3 offset-md-1">
              <div className="d-flex flex-column justify-content-between">
                <div className="stand-out-text">FREE</div>
                <div className="small-text">
                  Agency portal comes completely free. Invite your entire team at no extra cost.
                  <br />
                  <br />
                  <a
                    title='Learn more'
                    href="/agencies"
                    target="_blank"
                  >LEARN MORE HERE...</a>
                </div>
              </div>
            </div>
          </section>
          <secion className="row">
          <div className="cta-details2 col-12 col-md-12 col-xl-12 offset-xl-1">
            <p>
              See how LinkedHacker grew to 200+ clients with QuickMail:
            </p>
          </div>
          <div className="cta-details col-12 col-md-12 col-xl-12 offset-xl-1">
              <div
                className="wistia_responsive_padding"
                style={{ padding: "56.25% 0 0 0", position: "relative" }}
              >
                <div
                  className="wistia_responsive_wrapper"
                  style={{
                    height: "100%",
                    left: 0,
                    position: "absolute",
                    top: 0,
                    width: "100%"
                  }}
                >
                  <span
                    className="wistia_embed wistia_async_qnk7mjpf0i popover=true videoFoam=true"
                    style={{
                      display: "inline-block",
                      height: "100%",
                      position: "relative",
                      width: "100%"
                    }}
                  >
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </secion>
        </div>
      </div>
      <div
        className="container padded p-relative"
        style={{ zIndex: '10', marginTop: '-4rem', marginBottom: '4rem' }}
      >
        <section className="row" id="testimonial">
          <div className="col-12 col-md-8 col-xl-10 offset-xl-1 mx-auto">
            <div className="featured-testimonial">
              <StaticImage
                // className="img-fluid testimonial-card d-block mx-auto lazyload"
                className="img-fluid testimonial-card mx-auto"
                src="../../static/assets/testimonial-rachel.webp"
                width={700}
                height={443}
                alt="Testimonial by Rachel"
              />
            </div>
          </div>
        </section>
      </div>


      <div className="container padded p-relative">
        <section className="row" id="feature-comparison">
          <div className="col-12 col-xl-10 offset-xl-1 text-center">
            <h2>Features by Plan</h2>
          </div>
          <div className="d-none d-lg-block col-12 col-xl-10 offset-xl-1">
            <div className="grid">
              <div className="header">Features</div>
              {activePlanKeys.map((key) => (
                <div className="header text-capitalize" key={key}>
                  <div>{planName[key]}</div>
                </div>
              ))}
              {featureData.map((row, index) => {
                const feature = row.feature
                const planValues = activePlanKeys.map((key) => row.plans[key])
                const icon = (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="12" fill="#545ff5"/>
                    <path d="M6 12L10 16L18 8" stroke="#ffffff" stroke-width="3"/>
                  </svg>
                )
                return (
                  <React.Fragment key={index}>
                    <div className="cell feature-details">
                      <b>{feature.title}</b>
                      <div className="description">{feature.description}</div>
                    </div>
                    {planValues.map((cellValue, i) => (
                      <div
                        className={
                          'cell plan-details ' + (feature.bold ? 'bold' : '')
                        }
                        key={`${feature.title}${i}`}
                      >
                        {typeof cellValue === 'boolean'
                          ? cellValue
                            ? icon
                            : ''
                          : cellValue}
                      </div>
                    ))}
                  </React.Fragment>
                )
              })}
              <div className="cell feature-details cta">
                Ready To Get Started?
              </div>
              {activePlanKeys.map((key) => (
                <div className="cell plan-details cta" key={`${key}-cta`}>
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn d-block"
                  >
                    Try for Free
                  </a>
                  <small className="price-clarification">
                    {key === 'free' ? '100% Free' : '14-Day Trial'}
                  </small>
                </div>
              ))}
            </div>
          </div>
          <div className="d-block d-lg-none col-12 col-md-8 mx-auto">
            {featureDataForMobile.map((planData, index) => {
              return (
                <div className="stack" key={index}>
                  <div className="header text-capitalize text-center">
                    {planData.plan}
                  </div>
                  {planData.features.map(
                    (feature, i) =>
                      feature && (
                        <div className="item" key={`${feature.title}${i}`}>
                          <b>{feature.title}</b>
                          <div className="description">
                            {feature.description}
                          </div>
                        </div>
                      )
                  )}
                  <a
                    title="Try for Free"
                    href="https://next.quickmail.com/signup"
                    className="btn btn-primary purple-btn d-block"
                  >
                    Try for Free
                  </a>
                  <small className="price-clarification">
                    {planData.plan === 'free' ? 'Forever Free' : '14-Day Trial'}
                  </small>
                </div>
              )
            })}
          </div>
        </section>
      </div>

      <div className="container padded p-relative">
        <section className="row" id="faq">
          <div className="col-12 col-xl-10 offset-xl-1 text-center">
            <h2 className="py-5">FAQ</h2>
          </div>
          <div className="d-none d-lg-block col-12 col-xl-10 offset-xl-1">
            <h3 className="question">
              Can I downgrade or cancel my plan anytime?
            </h3>
            <p className="answer">
              Yes - No contract is required to sign up. Use QuickMail for a month or 2, no problem.
              To update or cancel the subscription, just go to your Account Settings / Billing/Plan.
              When changes happen to your subscription, we prorate and calculate usage to the second.
            </p>
            <h3 className="question">
              Will I be charged automatically after the free trial ends?
            </h3>
            <p className="answer">
              Nope. No one likes surprises. We won't charge your card nor subscribe your account to a paid plan when the free trial ends. That's a no go for us, you stay in control of your purchase experience.
            </p>
            <h3 className="question">
              Why do I need to add my card info to sign up if it's free?
            </h3>
            <p className="answer">
              We require everyone to add their card information to prevent spammers from creating many accounts and abusing our system. That way we can provide a stable system and robust email deliverability for all our clients like we had through the years. Our trial ends without creating an automatic subscription, so you are safe.
            </p>
            <h3 className="question">
              Do you offer cold email marketing or lead generation services with QuickMail?
            </h3>
            <p className="answer">
              QuickMail is a platform for your outbound, sending cold emails at scale and handling replies. We don't provide cold email marketing or lead generation service, we focus on building the best platform.
              We recommend joining our
              <a
                title="QuickMail Facebook Group"
                href="https://www.facebook.com/groups/quickmail"
                className="px-1"
                target="_blank"
              >
                Facebook group
              </a>
              and asking for help as there are cold email experts and agency owners that are members of the group.
            </p>
            <h3 className="question">
              What IP addresses does QuickMail use?
            </h3>
            <p className="answer">
              QuickMail does not send the email through proprietary servers. QuickMail automates sending emails from the email account added as an inbox, so the IP address of the email provider will be the one used to send the email.
              E.g. If the inbox is Gmail, Google IPs will be used to send.
            </p>
            <h3 className="question">
              What servers does QuickMail use?
            </h3>
            <p className="answer">
              We automate sending emails from the email provider of that inbox. Emails are sent using the email provider's servers. E.g. if you have an Outlook inbox, Microsoft servers will send the email. We only tell Microsoft to send an email to certain email addresses.
              This way the emails you send are genuinely looking like emails you would send yourself from your email address.
            </p>
            <h3 className="question">
              Can I use multiple email addresses for sending emails?
            </h3>
            <p className="answer">
              Yes, it's possible to send emails from multiple email addresses (same of different domains) to distribute the volume of emails with ease using our advanced
              <a
                title="QuickMail Inbox Rotation Feature"
                href="/inbox-rotation"
                className="px-1"
                target="_blank"
              >
                Inbox Rotation
              </a>
              system. You won't have to create multiple email campaigns to send emails from different inboxes, you can assign multiple email addresses to the same campaign.
            </p>
            <h3 className="question">
              How many team members can I add?
            </h3>
            <p className="answer">
              We don't punish you for growing your team. You may add as many team members as needed at no additional cost. No more sharing login and password, everyone on your team can have their own login details. Our auditing log helps you to keep an eye on what your members are doing.
            </p>
            <h3 className="question">
              Can I send emails to people who haven't subscribed (opt-in) yet?
            </h3>
            <p className="answer">
              Yes, we are a cold outreach tool. It's made to open business with companies you never contacted before. It's no different from phoning a company and asking them if they would be open to your value proposition, except this is done by email.
            </p>
            <h3 className="question">
              What integrations do you offer?
            </h3>
            <p className="answer">
              We offer different integrations to help people automate their workflow and make them more effective. Here's the list of integrations that we are currently supporting:
              <a
                title="QuickMail Integration"
                href="/integrations"
                className="px-1"
                target="_blank"
              >
                Integration
              </a>

            </p>
          </div>
        </section>
      </div>
      <FooterBanner />
    </div>
    </>
  )
}
